export function courseAppearanceTemplates () {
  return [
    {
      name: 'Full size centered',
      value: 'full_size_centered',
      size: '16:9',
      img: require('@/assets/img/templates/full_size_centered.png')
    },
    {
      name: 'Full size left',
      value: 'full_size_left',
      size: '16:9',
      img: require('@/assets/img/templates/full_size_left.png')
    },
    {
      name: 'Separated',
      value: 'separated',
      size: '1:3',
      img: require('@/assets/img/templates/separated.png')
    },
    {
      name: 'With sidebar',
      value: 'with_sidebar',
      size: null,
      img: require('@/assets/img/templates/with_sidebar.png')
    },
    {
      name: 'Without header',
      value: 'without_header',
      size: null,
      img: require('@/assets/img/templates/without_header.png')
    }
  ]
}

export function courseAppearanceFontSizes () {
  return [
    {
      name: 'Small',
      key: 'small'
    },
    {
      name: 'Medium',
      key: 'medium'
    },
    {
      name: 'Large',
      key: 'large'
    }
  ]
}

export function courseAppearanceTopIndent () {
	return [
		{
			name: 'No',
			key: 'no'
		},
		{
			name: 'Small ',
			key: 'small '
		},
		{
			name: 'Medium',
			key: 'medium'
		},
		{
			name: 'Large',
			key: 'large'
		}
	]
}
