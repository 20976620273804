import { mapActions, mapGetters, mapMutations } from "vuex";
import mixins from "@/mixins";
import course from "@/mixins/course";
import validation from "@/mixins/validation";

import MainEditor from "@/components/editor/index.vue";
import ImageFile from "@/components/image-file/index.vue";

import { camelCase, snakeCase } from "lodash";
import {
  courseAppearanceTemplates,
  courseAppearanceFontSizes,
  courseAppearanceTopIndent
} from "../../helpers/appearance";

export default {
  name: "courses-appearance",
  mixin: [mixins, course, validation],
  data() {
    return {
      validStore: false,
      validHeader: false,
      header: {
        images: "",
        title: "",
        statusId: "",
        colorBackground: "",
        colorTitle: "",
        colorDescription: "",
        colorBreadcrumbs: "",
        colorRatingActive: "",
        colorRatingInactive: "",
        colorButtonStartContinueStudyingText: "",
        colorButtonStartContinueStudyingTextHover: "",
        colorButtonStartContinueStudying: "",
        colorButtonStartContinueStudyingHover: "",
        colorButtonWatchTrailerText: "",
        colorButtonWatchTrailerTextHover: "",
        colorButtonWatchTrailer: "",
        colorButtonWatchTrailerHover: "",
        fontSize: "",
        widgetBestStudents: false,
        widgetMySkills: false,
        widgetPersonalRecommendations: false,
        titleTopIndent: "",
        subtitleDescription: ""
      },
      store: {
        image: "",
        sort: "",
        description: "",
        statusId: ""
      }
    };
  },
  components: {
    ImageFile,
    MainEditor
  },
  computed: {
    ...mapGetters({
      storeData: "coursesAppearance/store",
      storeLoading: "coursesAppearance/storeLoading",
      updateStoreLoading: "coursesAppearance/updateStoreLoading",
      currentCourse: "coursesInfo/info",
      setting: "courses/setting",
      headerData: "coursesAppearance/header",
      headerLoading: "coursesAppearance/headerLoading",
      updateHeaderLoading: "coursesAppearance/updateHeaderLoading",
      updateImageLoading: "coursesAppearance/updateImageLoading",
      updateImageCourseLoading: "coursesInfo/updateImageLoading"
    }),
    courseAppearanceTemplates,
    courseAppearanceFontSizes,
    courseAppearanceTopIndent,

    selectImgSizeHeader() {
      if (!this.currentCourse) return "";
      const template = this.currentCourse.template;
      const currentTemplate = this.courseAppearanceTemplates.find(
        e => e.value === template
      );
      if (!currentTemplate) return "";
      return currentTemplate.size;
    }
  },
  created() {
    if (!this.storeData) {
      this.fetchStore({ id: this.$route.params.course_id }).then(() => {
        this.setContent("store");
      });
    } else {
      this.setContent("store");
    }
    if (!this.headerData) {
      this.fetchHeader({ id: this.$route.params.course_id }).then(() => {
        this.setContent("header");
      });
    } else {
      this.setContent("header");
    }
    this.setCourseStep(4);
  },
  methods: {
    ...mapActions({
      fetchStore: "coursesAppearance/APPEARANCE_GET_STORE",
      fetchHeader: "coursesAppearance/APPEARANCE_GET_HEADER",
      updateStore: "coursesAppearance/APPEARANCE_UPDATE_STORE",
      updateHeader: "coursesAppearance/APPEARANCE_UPDATE_HEADER",
      updateImage: "coursesAppearance/APPEARANCE_STORE_UPDATE_IMAGE",
      updateImageHeader: "coursesAppearance/APPEARANCE_HEADER_UPDATE_IMAGE",
      updateImageCourseGeneral: "coursesInfo/INFO_UPDATE_IMAGE"
    }),
    ...mapMutations({}),

    updateImageLocal(params) {
      params.append("course_id", this.$route.params.course_id);
      params.append("store_id", this.storeData.id);
      params.append("type_id", 1);
      this.updateImage(params).then(() => {
        this.$toasted.success(this.$t("success_updated"));
      });
    },
    updateImageLocalHeader(params) {
      params.append("course_id", this.$route.params.course_id);
      params.append("header_id", this.headerData.id);
      params.append("type_id", 1);

      const payload = { params, type: "desktop" };

      this.updateImageHeader(payload).then(() => {
        this.$toasted.success(this.$t("success_updated"));
      });
    },
    updateImageLocalHeaderMobile(params) {
      params.append("course_id", this.$route.params.course_id);
      params.append("header_id", this.headerData.id);
      params.append("type_id", 2);

      const payload = { params, type: "mobile" };

      this.updateImageHeader(payload).then(() => {
        this.$toasted.success(this.$t("success_updated"));
      });
    },
    updateImageLocalGeneral(params) {
      params.append("course_id", this.$route.params.course_id);
      params.append("type_id", 1);
      this.updateImageCourseGeneral(params).then(() => {
        this.$toasted.success(this.$t("success_updated"));
      });
    },
    setContent(key = "store") {
      if (key === "store") {
        const data = this.storeData;
        const item = {};
        for (const i in data) {
          item[camelCase(i)] = data[i];
        }
        Object.assign(this.store, item);
      } else {
        const data = this.headerData;
        const item = {};
        for (const i in data) {
          item[camelCase(i)] = data[i];
        }
        Object.assign(this.header, item);
      }
    },
    createRequestPayload(key = "store") {
      if (key === "store") {
        const data = this.store;
        const item = {};
        Object.keys(data).forEach(e => {
          item[snakeCase(e)] = data[e];
        });
        return item;
      } else {
        const data = this.header;
        const item = {};
        Object.keys(data).forEach(e => {
          item[snakeCase(e)] = data[e];
        });
        item.title = this.currentCourse.name;
        return item;
      }
    },
    submitStore() {
      this.$refs.formStore.validate();
      if (this.validStore) {
        this.updateStore(this.createRequestPayload("store")).then(() => {
          this.$toasted.success(this.$t("success_updated"));
        });
      }
    },
    submitHeader() {
      this.$refs.formHeader.validate();
      if (this.validHeader) {
        this.updateHeader(this.createRequestPayload("header")).then(() => {
          this.$toasted.success(this.$t("success_updated"));
        });
      }
    },
    back() {
      this.goToStep("courses.components", 3);
    }
  }
};
